import React from "react";
import styled from "styled-components";
import "../App.css";

import Header from "../components/header";
import Nav from "../components/nav";
import Burger from "../components/burger";
import Socials from "../components/socials";
import Footer from "../components/footer";

import Open from "../images/nav/opening-hours.png";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 100%;
  overflow: hidden;
  min-height: 100vh;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const List = styled.ul`
  list-style-type: none;
  padding: 5px 5px;
  text-align: center;
`;

const Title = styled.h1`
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 45px;
  font-family: "OrgandaMN", "Organda", Inconsolata, sans-serif;
  padding-top: 20px;
`;

const ListItem = styled.li`
  padding: 5px;
`;

const Text = styled.div`
  margin-bottom: 50px;
  font-size: 1em;
  width: 90%;
  text-align: center;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;
const Promo = styled.div`
  margin: 15px 0;
  font-size: 0.8em;
`;

const FootLink = styled.a`
  text-decoration: none;
  color: #f8be98;
`;

var pageWidth = window.screen.availWidth;

const Hours = () => {
  return (
    <Container>
      <Header />
      {pageWidth > 768 ? <Nav /> : <Burger />}
      {/* <Title src={Open} alt="Opening Hours" /> */}
      <Title>Trading Hours</Title>
      <List>
        <ListItem>Open Wednesday to Saturday</ListItem>
        <ListItem>Closed Sunday*, Monday & Tuesday</ListItem>
        <ListItem>* Monthly Sunday Lunch</ListItem>
      </List>
      <List>
        <ListItem>Wednesday, Thursday & Friday 5 - 10pm</ListItem>
        <ListItem>Saturday 3pm - 10pm</ListItem>
        <ListItem> </ListItem>
        <ListItem>See What's On or follow us on socials for weekly specials & monthly events</ListItem>
      </List>
      <Title>What's on</Title>
      <List>
        <ListItem>~ Every Wednesday ~</ListItem>
        <ListItem>Ragu Bowls $25</ListItem>
        <ListItem>House-made pasta with meat or vegetarian ragu</ListItem>        
        <ListItem>Full Menu Available</ListItem>
      </List>
      <List>
        <ListItem>~ Every Thursday ~</ListItem>
        <ListItem>Steak Frites $35</ListItem>
        <ListItem>250gram Sirloin, Shoestring Fries, </ListItem>
        <ListItem>Mixed Leaves, Housemade Sauce</ListItem>
        <ListItem>Full Menu Available</ListItem>
      </List>
      <List>
        <ListItem>~ Every Saturday ~</ListItem>
        <ListItem>Aperitivo 3-5pm</ListItem>
        <ListItem> </ListItem>
        <ListItem>Tap Beer & House Vino $8</ListItem>
        <ListItem>Spritz $10</ListItem>
        <ListItem>Negroni $12</ListItem>
        <ListItem>Full Menu Available</ListItem>
      </List>
      <List>
        <ListItem>~ Monthly Event ~</ListItem>
        <ListItem>Sunday Long Lunch 12-4pm</ListItem>
        <ListItem>3-course set menu / $50pp</ListItem>
        <ListItem>6th October</ListItem>
        <ListItem>3rd November</ListItem>
        <ListItem>1st December</ListItem>
      </List>
      <Footer />
      <Socials />
      <Text>
        Check out our rowdy neighbor{" "}
        <FootLink
          href="https://www.mortys-joint.com//"
          target="_blank"
          rel="noopener noreferrer"
        >
          Morty's Joint (mortys-joint.com)
        </FootLink>{" "}
        for all your fried chicken & burger needs.
      </Text>
      <Promo>Created and hosted by HolisTechAus &copy;2022</Promo>
    </Container>
  );
};

export default Hours;
